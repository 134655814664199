import React, { Component } from 'react';
import {SwatchesPicker} from 'react-color';

export default class ColoringStep extends Component {


    handleChange = (color, event) => {
        this.props.handleColor(color);
    }

    render() {
        return (
            <div className="editor-block">
                <h2>Color the map</h2>
                <p>Select a color and click on the map the regions you want to color.</p>
                <SwatchesPicker 
                    height="540"
                    color={ this.props.color }
                    onChange = {this.handleChange}/>
                {false && (<div>
                    <span>show country names</span>
                    <label className="switch">
                        <input className="checkbox"/>
                        <span className="slider round"></span>
                    </label>
                </div> )}
            </div>
        )
    }
}