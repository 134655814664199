import React, { Component } from 'react';

export default class ExportStep extends Component {


    render() {
        return (
            <div className="editor-block">
                <h2>Export the map</h2>
                <div id="export-container">
                    <button className="wim-btn" onClick={(event) => this.props.handleExport('png')}>Download the map</button>
                </div>
                {false && (<button onClick={(event) => this.props.handleExport('jpeg')}>JPEG</button>)}
            </div>
        )
    }
}