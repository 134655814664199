import React, { Component } from 'react';
import * as d3 from "d3";
import rewind from '@turf/rewind';
import fileMap from "../../data/europe-countries.geojson";
import toImg from "../tools/svgToImage";

export default class EuropeCountries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "map"
        }
        this.mapRef = React.createRef();
    }
    componentDidMount () {
        this.drawMap(this);
    }
    
    render() {
        return (
            <div id={this.state.id} ref={this.mapRef}>

            </div>
        )
    }

    selectBackgroundFile = (region) => {

    }

    drawMap (that) {
        var id = "#" + this.state.id;
        // Width and height
        var w = 1100;
        var h = 500;

        // Projection
        var projection = d3.geoNaturalEarth1()
        .translate([w/2 - 150, h/2+575])
        .scale(600)

        var path = d3.geoPath( projection );

        var svg = d3.select(id)
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 " + w + " " + h)
        .style("border", "solid")
        .style("border-width", "1px")
        .style( 'border-color', '#D9D9D9' )
        .style("background-color", "white");

        // tooltip
        var tooltip = d3.select(id)
        .append("div")
        .style("opacity", 0)
        .attr("class", "wim-tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px");

        // A function that change this tooltip when the user hover a point.
        // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
        var mouseover = function(event, d) {
            tooltip
            .style("opacity", 1);          
        }

        var mousemove = function(event, d) {
            var x = event.pageX;
            var y = event.pageY;
            tooltip
            .html(d.properties.ADMIN)
            .style("left", (x + 15) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
            .style("top", (y - 15) + "px")
            d3.select(this)
                .attr("stroke-width", 2)
                .style("cursor", "pointer");
        }

        // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
        var mouseleave = function(event, d) {
            // reset black border of elements
            // set black border to elements
            tooltip.style("opacity", 0);
            d3.select(this)
                .attr("stroke-width", 0.26)
                .style("cursor", "default");
            
        }

        // on click feature
        var click = function (event, d) {
            d3.select(this).attr("fill",that.props.color);
            var legend = that.props.legend
            var legendExist = false
            for (var i = 0, l = legend.length; i < l; i++) {
                if (legend[i].color === that.props.color) {
                    legendExist = true;
                    break;
                }
            }
            if (!legendExist) {
                that.props.addToLegend();
            }
            
        }

        // draw map
        d3.json(fileMap).then(function(data) {

            var rawFeatures = data.features;

            // rewind features (otherwise it is not correct)
            var features = rawFeatures.map(function(feature) {
                return rewind(feature,{reverse:true});
        })
        // add map group
        var countries = svg.append("g").attr('id', 'countries');
        // draw map
        countries.selectAll("path")
            .data(features)
            .enter()
            .append("path")
            .attr("d", path)
            .attr( 'fill', that.props.baseColor)
            .attr( 'stroke', '#6f6f6f' )
            .attr( 'stroke-width', .26 )
            .on("mouseover", mouseover )
            .on("mousemove", mousemove )
            .on("mouseleave", mouseleave )
            .on("click", click );

        })

        // draw legend
        svg.append("g").attr('id', 'legend');

        // draw logo
        // initial position
        var locX = 20;
        var locY = 20;
        var logo = svg.append("g").attr('id', 'logo');
        logo.append("text")
         .attr("x", w - locX - 50) //(elements.legend[i].end.toString().length * 6 / 2) -> width letter
         .attr("y", h - (locY / 2))
         .text("worldinmaps.com")
         .style("font-size", "8px");

    }

    removeColor (color) {
        var that = this;
        var id = "#countries";
        var countries = d3.select(id);
        countries.selectAll('path').each(function(d,i) { 
            var element = d3.select(this);
            if(element.attr("fill") == color) {
                element.attr("fill",that.props.baseColor);
            }
        });
    }

    clearLegendMap = () => {
        var id = "#legend";
        var legendMap = d3.select(id);
        legendMap.selectAll("rect").remove();
        legendMap.selectAll("text").remove();
    }

    drawLegendMap = () => {
        // Width and height
        var w = 1100;
        var h = 500;
        var legend = this.props.legend;
        var heightBox = 24; 
        var widthBox= 40;

        // initial position
        var locX = 20;
        var locY = 20;
        var spaceY = 30;
        var id = "#legend";
        var legendMap = d3.select(id);
        
        // set title            
        legendMap.append("text")
         .attr("x", locX) //(elements.legend[i].end.toString().length * 6 / 2) -> width letter
         .attr("y", h - (locY / 2) + 5 - (legend.length)*spaceY - 40)
         .text(this.props.title)
         .style("font-size", "14px")
         .attr("alignment-baseline","middle");

        // draw legends
        for (var i = 0, l = legend.length; i < l; i++) {
            // rect
            var element = legend[i];
            if(element.label) {
                legendMap
                .append("rect")
                .attr("id", function() { 
                    console.log(element)
                    var color = element.color.substring(1);
                    return "wim-map-" + element.label + "-" + color;
                })
                .attr("x", locX)
                .attr("y", h - locY - (l-i)*spaceY)
                .attr("width", widthBox)
                .attr("height", heightBox)
                .style('fill', legend[i].color)
                .style( 'stroke', "#333333")
                .style( 'stroke-width', .5 );
    
                // label             
                legendMap.append("text")
                .attr("x", locX + widthBox +20) //(elements.legend[i].end.toString().length * 6 / 2) -> width letter
                .attr("y", h - (locY / 2) + 5 - (l-i)*spaceY)
                .text(element.label)
                .style("font-size", "12px")
                .attr("alignment-baseline","middle");
            }
        }
    }


    exportSVG = (typeImage) => {
        // var svg = this.mapRef.current.firstChild;
        // console.log(svg);
        // //var svgElement = React.cloneElement(svg, {});
        // var svgElement=svg.cloneNode(true)
        // console.log(svgElement);
        toImg('svg', 'map', {
            format: typeImage
          }).then(fileData => {
            //svg.replaceWith(svgElement)
         });
    };

    // exportSVG = (typeImage) => {
    //     // from https://levelup.gitconnected.com/draw-an-svg-to-canvas-and-download-it-as-image-in-javascript-f7f7713cf81f
    //     // get svg
    //     //var svgElement = document.getElementById('map').firstChild;
    //     var svgElement = this.mapRef.current.firstChild;

    //     // get width & height
    //     let {width, height} = svgElement.getBBox(); 

    //     // clone the svg node
    //     let clonedSvgElement = svgElement.cloneNode(true); // true for deep clone

    //     // create a blob object from the SVG
    //     let outerHTML = clonedSvgElement.outerHTML;
    //     let blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
    //     // create a URL for the blob
    //     let URL = window.URL || window.webkitURL || window;
    //     let blobURL = URL.createObjectURL(blob);

    //     // load the URL into an image element
    //     let image = new Image();
    //     image.onload = () => {
    //         console.log("image on load");
    //         // create a canvas with width and height of the SVG
    //         let canvas = document.createElement('canvas');
            
    //         canvas.widht = width;
            
    //         canvas.height = height;
    //         let context = canvas.getContext('2d');
    //         // draw image in canvas starting left-0 , top - 0  
    //         context.drawImage(image, 0, 0, width, height );
    //         // download the canvas as image
    //         // convert canvas to DataURL
    //         let downloadURL = canvas.toDataURL('image/' + typeImage);
            
    //         // download the image
    //         var download = function(href, name){
    //             var link = document.createElement('a');
    //             link.download = name;
    //             link.style.opacity = "0";
    //             document.append(link);
    //             link.href = href;
    //             link.click();
    //             link.remove();
    //           }
    //           download(downloadURL, "map." + typeImage);
              
    //     };
    //     image.src = blobURL;
        
    // }

    // exportJPG = () => {
    //     // let png = canvas.toDataURL(); // default png
    //     // let webp = canvas.toDataURL('image/webp');
    //     this.exportSVG('jpg');
        
    // }
}