import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise";

const initialState = {};
const middleware = [thunk, promiseMiddleware];
const reducers = combineReducers({
   
});

let store;

if (window.navigator.userAgent.includes("Chrome")) {
    store = createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware(...middleware),
            // window.__REDUX_DEVTOOLS_EXTENSION__ &&
            // window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
} else {
    store = createStore(
        reducers,
        initialState,
        compose(applyMiddleware(...middleware))
    );
}

export default () => {
    return store;
};