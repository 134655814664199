import React, { Component } from 'react';
import Editor from '../editor/editor';
import WorldMap from '../map/northAmericaCountries';
import Ads from '../ads';
export default class NorthAmericaCountriesPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            baseColor: "#d6dfd6",
            color: '#b71c1c',
            legend: [],
            title: undefined
        }
        this.map = React.createRef();
    }

    handleColor = (color) =>{
        this.setState({color: color.hex})
    }

    addToLegend = () => {
        var legendObject = {
            color: this.state.color,
            label: undefined
        }
        this.setState({legend: [...this.state.legend, legendObject]})

    }
    deleteFromLegend = (index) => {
        var legend = this.state.legend;
        var color = legend[index].color;

        // remove element from legend
        legend.splice(index,1);
        this.setState({legend: legend});

        // remove color on map
        this.map.current.removeColor(color);
        this.map.current.clearLegendMap();
        this.map.current.drawLegendMap();
    }
    handleLabel = (event, index) => {
        var legend = this.state.legend;
        legend[index].label = event.target.value;
        this.setState({legend: legend})
        this.map.current.clearLegendMap();
        this.map.current.drawLegendMap();
        
    }

    handleTitle = (event) => {
        this.setState({title: event.target.value})
        this.map.current.clearLegendMap();
        this.map.current.drawLegendMap();
        
    }

    handleExport = (typeFile) => {
        this.map.current.exportSVG(typeFile);
    }


    render() {

        return (
            <div className="">
                
                <div align="center" className="wim-billboard">
                    <Ads/>
                </div>
                <h1>Color and create custom maps with our Map maker</h1>
                <WorldMap 
                    baseColor = {this.state.baseColor}
                    color = {this.state.color}
                    legend = {this.state.legend}
                    title={this.state.title}
                    addToLegend = {this.addToLegend}
                    ref={this.map}/>
                <Editor 
                    color = {this.state.color}
                    handleColor = {this.handleColor} 
                    legend={this.state.legend}
                    handleTitle={this.handleTitle}
                    handleLabel={this.handleLabel}
                    deleteFromLegend={this.deleteFromLegend}
                    handleExport={this.handleExport}
                    />
            </div>
        )
    }
}

