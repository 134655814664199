import React from 'react';
import AppRouter from "./routers/AppRouter";
import './App.scss';
import "normalize.css";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  const store = configureStore();
  return (
    <div className="App">
      <Header/>
      <Provider store={store}>
        <AppRouter />
      </Provider>
      <Footer/>
    </div>
  );
}

export default App;
