import React, { Component } from 'react';
import worldMap from "../assets/img/worldmap.jpeg";
import usStatesMap from "../assets/img/us-states.jpeg";
import EuropeCountriesMap from "../assets/img/europe-countries.jpeg";
import NorthAmericaCountriesMap from "../assets/img/north-america-countries.jpeg";
import SouthAmericaCountriesMap from "../assets/img/south-america-countries.jpeg";
import AfricaCountriesMap from "../assets/img/africa-countries.jpeg";

import MapExplorer from "../assets/img/map-explorer.jpeg";
export default class Regions extends Component {

    selectRegion = (region) => {
        this.props.history.push('/' + region)
    }
    go2mapExplorer = () => {
        window.open("https://worldinmaps.com/workspace");
    }
    
    render() {
        return (
            <div className="regions">
                <h2>Start by selecting the background map you want to color</h2>
                <div className="type-maps-container">
                    <div onClick={() => {
                            this.selectRegion("world-map");
                        }}
                        className="type-maps"
                        >
                        <img src={worldMap} alt="world map" width="100%"/>
                        <div className="type-maps-label">World map</div>
                    </div>

                    <div onClick={() => {
                            this.selectRegion("US-states");
                        }}
                        className="type-maps"
                        >
                        <img src={usStatesMap} alt="US states map" width="100%"/>
                        <div className="type-maps-label">US States map</div>
                    </div>
                    <div onClick={() => {
                            this.selectRegion("north-america-countries");
                        }}
                        className="type-maps"
                        >
                        <img src={NorthAmericaCountriesMap} alt="North America countries map" width="100%"/>
                        <div className="type-maps-label">North America countries map</div>
                    </div>
                    <div onClick={() => {
                            this.selectRegion("south-america-countries");
                        }}
                        className="type-maps"
                        >
                        <img src={SouthAmericaCountriesMap} alt="South America countries map" width="100%"/>
                        <div className="type-maps-label">South America countries map</div>
                    </div>

                    <div onClick={() => {
                            this.selectRegion("europe-countries");
                        }}
                        className="type-maps"
                        >
                        <img src={EuropeCountriesMap} alt="Europe countries map" width="100%"/>
                        <div className="type-maps-label">Europe countries map</div>
                    </div>
                    <div onClick={() => {
                            this.selectRegion("africa-countries");
                        }}
                        className="type-maps"
                        >
                        <img src={AfricaCountriesMap} alt="Africa countries map" width="100%"/>
                        <div className="type-maps-label">Africa countries map</div>
                    </div>
                </div>
                <h2>Or start to draw points, lines and polygons on a map</h2>
                <div className="type-maps-container">
                    <div onClick={() => {
                            this.go2mapExplorer();
                        }}
                        className="map-explorer"
                        >
                        <img src={MapExplorer} alt="map-explorer" width="100%"/>
                    </div>
                </div>
            </div>
        )
    }
}
