import React from 'react';

export default class Ads extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display:"inline-block",
                width:"970px",
                height:"250px" }}
          data-ad-client='ca-pub-5209048279314856'
          data-ad-slot='3580141769'/>
    );
  }
}