import React from 'react';
import Ads from '../ads';
import Regions from '../regions';


export default class HomePage extends React.Component {


render () {
    return (
        <div>
            <div align="center" className="wim-billboard">
                <Ads/>
            </div>
            <h1>Color and create custom maps with our Map maker</h1>
            <div>
                <Regions history={this.props.history}/>
            </div>
        </div>
    );
  }
}