import React, { Component } from 'react';
import ColoringStep from './coloringStep';
import LegendStep from './legendStep';
import ExportStep from './exportStep';

export default class Editor extends Component {


    handleColor = (color) => {
        this.props.handleColor(color);
    }

    render() {
        return (
            <div className="editor-container">
                <ColoringStep 
                    handleColor = {this.props.handleColor}
                    color = {this.props.color}/>
                <LegendStep 
                    legend={this.props.legend} 
                    handleTitle={this.props.handleTitle}
                    handleLabel={this.props.handleLabel}
                    deleteFromLegend={this.props.deleteFromLegend}/>
                <ExportStep
                    handleExport = {this.props.handleExport}/>
            </div>
        )
    }
}