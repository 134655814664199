import React, { Component } from 'react';
import logo from '../assets/img/logo.png';

export default class Header extends Component {
    
    render() {
        return (
            <header>
                <div id="header" class="header-container header-container-normal">
                    <div><a href="https://terrascientifica.com" id="logoTS" >Terra <sub>Scientifica</sub></a></div>
                    <div><a id="logo" class="navbar-brand" href="https://worldinmaps.com"><img src={logo} alt="World in maps" height="30" width="195"/></a></div>
                </div>
            </header>
        )
    }
}