import React, { Component } from 'react';

export default class LegendStep extends Component {
    
    handleTitle = (event) => {
        this.props.handleTitle(event);
    }

    handleLabel = (event, index) => {
        this.props.handleLabel(event, index);
    }
    deleteFromLegend = (index) => {
        this.props.deleteFromLegend(index);
    }
    render() {
        console.log(this.props.legend);
        var legendElements = this.props.legend.map(function(element, index){
            return (
                <li key={element.color} 
                    style={{
                        display: 'flex',
                        paddingBottom: '10px',
                        justifyContent: 'space-between'
                    }}>
                    <div key={element.color + "-div1"} style={{
                        height: '24px', 
                        width: '40px',
                        backgroundColor: element.color 
                    }}></div>
                    <div key={element.color + "-div2"}  className="input-group">
                        <input type="text" className="form-control" placeholder="label" onChange={(event) => this.handleLabel(event, index)}/>
                    </div>
                    <button key={element.color + "-btn"}  className="button--icon" onClick={(event) => this.deleteFromLegend(index)}>x</button>
                </li>);
          }.bind(this))
        return (
            <div className="editor-block">
                <h2>Configure the legend</h2>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Title Legend" onChange={(event) => this.handleTitle(event)}/>
                </div>
                <div>
                    <ul>
                        {legendElements}
                    </ul>
                </div>
            </div>
        )
    }
}