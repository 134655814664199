import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WorldMapPage from "../components/pages/worldMapPage";
import EuropeCountriesPage from "../components/pages/europeCountriesPage";
import AfricaCountriesPage from "../components/pages/africaCountriesPage";
import NorthAmericaCountriesPage from "../components/pages/northAmericaCountriesPage";
import SouthAmericaCountriesPage from "../components/pages/southAmericaCountriesPage";
import UsStatesPage from "../components/pages/usStatesPage";
import HomePage from "../components/pages/homePage";

const AppRouter = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/" component={HomePage} exact={true} />
            <Route path="/world-map" component={WorldMapPage} exact={true} />
            <Route path="/US-states" component={UsStatesPage} exact={true} />
            <Route path="/europe-countries" component={EuropeCountriesPage} exact={true} />
            <Route path="/africa-countries" component={AfricaCountriesPage} exact={true} />
            <Route path="/north-america-countries" component={NorthAmericaCountriesPage} exact={true} />
            <Route path="/south-america-countries" component={SouthAmericaCountriesPage} exact={true} />
        </Switch>
    </BrowserRouter>
);
export default AppRouter;